const configFile = process.env.REACT_APP_ENV
const {SERVER_URL,APP_NAME,LOGO,FbLoginID,URL,LOGINIMG,AppleClientId,GmailClientId,OrcidClientId,AppVersion,RedirectUri,Cover} = require('/'+configFile+'.js');
export const userLogin=SERVER_URL + "public/login-payload";
export const contactUs=SERVER_URL + "sendemail";
export const resendMail=SERVER_URL+'resend';
export const getbook=SERVER_URL+'api/books?start=';
export const getbookinfo=SERVER_URL+'api/book/';
export const getJournals=SERVER_URL+'api/journals?start=';
export const getJournalinfo=SERVER_URL+'api/journal/';
export const subscriptionURL=SERVER_URL+'user/library/subscription/get';
export const favoriteURL=SERVER_URL+'user/library/get';
export const signupURL=SERVER_URL+'mobile/signup';
export const forgotURL=SERVER_URL+'mobile/forgotpassword';
export const passwordresetURL=SERVER_URL+'resetpassword';
export const bookcover=Cover;
export const pdfURL=SERVER_URL+'content/book/pdf/';
export const epubURL=SERVER_URL+'content/book/epub/';
export const articlepdfURL=SERVER_URL+'content/article/pdf/';
export const articleepubURL=SERVER_URL+'content/article/epub/';
export const googlesignin=SERVER_URL+'googlesignin';
export const fblogin=SERVER_URL+'fblogin';
export const saveandremovefav=SERVER_URL+'user/library/save';
export const bookpath=SERVER_URL+'book/isbn/';
export const bookdetails=SERVER_URL+'api/book/isbn/';
export const orgbookdetails=SERVER_URL+'institutions/';
export const resetpassword=SERVER_URL+'user/resetpassword';
export const orcidlogin=SERVER_URL+'orcidlogin';
export const applelogin=SERVER_URL+'mobile/applelogin';
export const home=SERVER_URL+'api/home';
export const subject=SERVER_URL+'api/content/subject/';
export const search=SERVER_URL+'search/api?';
export const rateus=SERVER_URL+'user/app/review/save';
export const checkdevicepair=SERVER_URL+'devicepair/users/';
export const devicepairupdate=SERVER_URL+'unauthservices/devicepair/';
export const passcodevalidation=SERVER_URL+'novapasscodevalidation';
export const catalog=SERVER_URL+'api/catalog';
export const series=SERVER_URL+'api/series/';
export const organization=SERVER_URL+"api/check-user-organization"
export const orgsearch=SERVER_URL+"institutions/"
export const bookavilable=SERVER_URL+"forms/b2b-customer-asking-for-book-avilable-notifiction/save"
export const contributors=SERVER_URL+"api/contributors/get"
export const subjects=SERVER_URL+"/api/subjects/get"
export const logo=LOGO;
export const loginimg=LOGINIMG;
export const appName=APP_NAME;
export const fbloginid=FbLoginID;
export const url=URL;
export const serverurl=SERVER_URL;
export const appleclientid=AppleClientId;
export const gmailclientid=GmailClientId;
export const orcidclientid=OrcidClientId;
export const appversion=AppVersion;
export const redirectUri=RedirectUri;





