import React, {Component} from "react";
import { Form, Input, Button, Checkbox, Empty, Modal,message,Select,Popover} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { forgotURL } from '../../api/api';
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

class NormalForgotPasswordForm extends Component {
  constructor(){
      super();
      this.state={
        forgotVar:true, 
        verfiyVar:false,
        loadings:false,
        text:"",
        btnText:"Reset Password",
        instarray:[]
      }
  }

  componentDidMount=()=>{
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
  };

  fetch("https://livedplacespublishing.com/institutions/get?term=", requestOptions,)
      .then(response => response.json())
      .then(result => {
        this.setState({
          instarray:result
        })
          
         

      })
      .catch(error => {
          
      });

  }

   content =()=> (
    <div>
      {this.state.instarray.map((item)=><p onClick={()=>{
         this.setState({
          text:item.label
        })
        window.location.href =item.loginUrl

      }}>{item.label}</p>)}
    </div>
  );

   updatetext = event => {
    console.log(event.target.value)
    this.setState({
      text:event.target.value
    })
      var requestOptions = {
          method: 'GET',
          redirect: 'follow'
      };

      fetch("https://livedplacespublishing.com/institutions/get?term="+event.target.value, requestOptions,)
          .then(response => response.json())
          .then(result => {
            this.setState({
              instarray:result
            })
              
             

          })
          .catch(error => {
              
          });

  
}



  onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
 getInst=(e)=>{
  console.log(e)

  }
render(){
  const {forgotVar, verfiyVar} = this.state;
  return (
    <div>
     <div className="instwish">Enjoy full access to Lived Places Publishing by signing in through your institution.</div>
      {forgotVar == true ? 
      <Form  name="basic"
        initialValues={{ remember: true }}
        onFinish={this.onFinish}
        onFinishFailed={this.onFinishFailed}
      >
        <Form.Item
          // label="Email"
          name="institution"
        >
              <Popover content={this.content} placement="bottom"  trigger="focus" b>

            <input type="search" className="searchboxfiled" placeholder="Find by your institution name" name="search" value={this.state.text} onChange={this.updatetext} />
          </Popover>
        </Form.Item>
       
          
        
      </Form>:""}
    {verfiyVar == true ?
    <Empty
          description={
            <span style={{marginBottom:"50px"}}>
              <span><b>Forgot Password</b></span>
              <br/>
              Email was sent with an reset password link.
            </span>
          }
        >
          {/* <Button type="primary">Create Now</Button> */}
    </Empty>:""}
    </div>
  );
}
};

export default NormalForgotPasswordForm;