import React, { useEffect, useState } from 'react';
import Error500 from '../error/500';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { home,bookcover } from '../../api/api'
import { Row, Col,Image} from 'antd';
import { setSubject, setNewBooks, setBestRatingBooks, setOpenAccessBooks, setNewJournals, setBestRatingJournals } from '../../redux/actions/dashboardActions';
import ListCard from '../../component/dashboard/list_Card';
import SubjectCard from '../../component/dashboard/subject_Card';
import Space from '../../component/dashboard/space';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Offline from '../error/offline';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Dashboard = () => {
  const history = useHistory();
  const [paidBook, setPaidBook] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const subject = useSelector((state) => state.App.subject);
  const newbooks = useSelector((state) => state.App.newbooks);
  const bestratingbooks = useSelector((state) => state.App.bestratingbooks);
  const openaccessbooks = useSelector((state) => state.App.openaccessbooks);
  const newjournals = useSelector((state) => state.App.newjournals);
  const bestratingjournals = useSelector((state) => state.App.bestratingjournals);
  const isonline = useSelector((state) => state.App.isonline);
  const [error, setError] = useState(false)
  const isOnLine=localStorage.getItem('isonline')
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2, 
  
  };
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0)
    fetchDashboardData()
  }, [])

 


  const fetchDashboardData = async () => {
    setIsLoading(true)
    let axios = require('axios');
    let config = {method:'get',url: home,};
    axios(config)
      .then((response) => {
        dispatch(setSubject(response.data.subjectList));
        dispatch(setNewBooks(response.data.recentbook));
        dispatch(setBestRatingBooks(response.data.bestratingbooks));
        dispatch(setOpenAccessBooks(response.data.openaccessbooks));
        dispatch(setNewJournals(response.data.recentJournals));
        dispatch(setBestRatingJournals(response.data.bestRatingJournals));
        setPaidBook(response.data.bestpaidbooks);
        setIsLoading(false);})
      .catch(function (error) { setError(true); setIsLoading(false) });
  }

  if(isOnLine=="offline"){
    return(<Offline/>)
  }
 
  
  if (error) {
    return (
      <Error500 />
    )
  }
  return (
    <div>
      <Row style={{ "padding": "0px", "margin": "0px 0px 0px 0px" }}>
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>
          <SubjectCard data={subject}  isLoading={isLoading} />
          <Space hig="10px" color="#fff" />
        
          <ListCard cardtitel="New Releases" data={newbooks} titel="newreleases" isBook={true} access={"paid"} isLoading={isLoading} />
          <Space hig="1px" color="#ECF0EF" />
          {/* <ListCard titel="Best Rated Journals" data={bestratingjournals} isBook={false} isLoading={isLoading} />
      <Space hig="1px" color="#ECF0EF"/> */}
          <ListCard cardtitel="Books By Rating" titel="booksbyrating" data={bestratingbooks} isBook={true} access={"paid"} isLoading={isLoading}  />
          <Space hig="1px" color="#ECF0EF" />
          {/* <ListCard titel="Recent Journals" data={newjournals} isBook={false} isLoading={isLoading} />
      <Space hig="1px" color="#ECF0EF"/> */}
          {/* <ListCard cardtitel="Paid Book" titel="paidbook" data={paidBook} isBook={true} access={"paid"} isLoading={isLoading} gotolist={() => { goToList("Paid Book") }}  />
      <Space hig="1px" color="#ECF0EF"/> */}
          <ListCard cardtitel="Open Access Books" titel="openaccessbooks" data={openaccessbooks} isBook={true} isLoading={isLoading} access={"free"}  />
          <Space hig="1px" color="#ECF0EF" />
          <Space hig="60px" color="#fff" />
        </Col>
      </Row>
    </div>
  );
}

export default Dashboard;
