import React, { useState, useEffect } from 'react';
import Header from '../../component/dashboard/header';
import {Row, Col,message} from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { sethometab,setIsonline } from '../../redux/actions/dashboardActions';
import { HomeOutlined,UnorderedListOutlined,ProfileOutlined} from '@ant-design/icons';
import Dash from './dashboard';
import Lib from '../library/library';
import Coll from './allcollection';
import Int from './Institutionshome';
import './dashboardStyles.css';
import 'antd-mobile/dist/antd-mobile.css';
import { organization } from '../../api/api';
import ListCard from '../../component/dashboard/list_Card';
import Space from '../../component/dashboard/space';



const Home = () => {
    const history = useHistory();
    const [loading, setloading] = useState(false);
    const dispatch = useDispatch();
    const state = useSelector((state) => state.App);
    const configFile = process.env.REACT_APP_ENV
    const showuser = useSelector((state) => state.App.showlogin);
    const [islogin, setislogin]=useState(false)
    const [org, setorg]=useState([])
    const emptyData=[{}, {}, {}, {}, {}]


    useEffect(() => {
        window.scrollTo(0, 0)
        if(configFile=="lpp"){
        const nova_userCredentials = JSON.parse(localStorage.getItem('nova_userCredentials'));
        if (nova_userCredentials) {
            getSubscription();

        }else{
            getSubscriptionno();

        }
    }
   
           
       
    }, [])
    const getSubscription = async () => {
        setloading(true)
        const nova_userCredentials = JSON.parse(localStorage.getItem('nova_userCredentials'));

        let axios = require('axios');
        let config = {
            method: 'get',
            url: organization,
            headers: {
                'apikey': nova_userCredentials.apikey
            }
        };
        axios(config)
            .then((response) => {
                if(response.data.data.length==0){
                    setloading(false)

                }else{
                    history.replace('./institutions')

    
                }
            })
            .catch(function (error) {
                setloading(false)


            });
    }

    const getSubscriptionno = async () => {
        setloading(true)

        let axios = require('axios');
        let config = {
            method: 'get',
            url: organization,
           
        };
        axios(config)
            .then((response) => {
                console.log("organization",response.data.data.length)
                if(response.data.data.length==0){
                    setloading(false)

                }else{
                    history.replace('./institutions')

    
                }
           
            })
            .catch(function (error) {
                setloading(false)


            });
    }
    const login = () => {
        history.replace('/login');
    }
    


const sethome = () => dispatch(sethometab("home"))
const setlib = () =>{
    const nova_userCredentials = JSON.parse(localStorage.getItem('nova_userCredentials'));
        if (nova_userCredentials != null) {
            if (nova_userCredentials.emailVerified != 1) {
                history.push('/login');
                    } else {
              
                dispatch(sethometab("lib"))

            }
        }
        else {
            history.push('/login');
        }

}
const setdow = () => dispatch(sethometab("coll"))
const setInt = () => dispatch(sethometab("int"))


return (
        <div className="dashboard">
        <Row style={{ "padding": "0px", "margin": "0px 0px 0px 0px" }}>
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>
                 <Header /> 
                {state.hometab=="home"?
                        <Dash/>
                        : null}
                         {state.hometab=="lib"?
                        <Lib/>
                        : null}
                          {state.hometab=="coll"?
                        <Coll/>
                        : null}
                        
               </Col>
        </Row>
       <div className="tabbarrcontiner">
               <div className="tabbarr" >
                    <div className="tabbar_itemm" onClick={() => sethome()} style={{  color: state.hometab=="home" ? "#ffffff" : "#000",backgroundColor:state.hometab=="home" ? "#F36C23" : "#D6D6D6", }}><HomeOutlined style={{fontSize:"15px"}}/>Home </div>
                    <div className="tabbar_itemm" onClick={() => setlib()} style={{  color: state.hometab=="lib" ? "#ffffff" : "#000",backgroundColor:state.hometab=="lib" ? "#F36C23" : "#D6D6D6", }}><ProfileOutlined style={{fontSize:"15px"}} />Library</div>
                    {configFile=="lpp"?<div className="tabbar_itemm" onClick={() => setdow()} style={{ color: state.hometab=="coll" ? "#ffffff" : "#000" ,backgroundColor:state.hometab=="coll" ? "#F36C23" : "#D6D6D6",}}><UnorderedListOutlined  style={{fontSize:"15px"}} />Collections</div>:null}

                </div>
                </div>
      </div>)
}

export default Home;